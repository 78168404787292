export const CourseStatus = {
    DRAFT: { displayName: '準備發佈' },
    PUBLISHED: { displayName: '已發佈' },
    CONFIRMED: { displayName: '確認成班' },
    CANCELLED: { displayName: '已取消' },
    COMPLETED: { displayName: '已完成' },
}

export const SalesMode = {
    DIRECTSALES: {displayName: '購買'},
    CROWDFUNDED: {displayName: '眾籌'},
}

export const CourseRegistrationStatus = {
    PENDING_PAYMENT: { displayName: '等待付款' },
    PENDING_CONFIRMATION: { displayName: '等待確認' },
    CONFIRMED: { displayName: '已確認付款' },
    COMPLETED: { displayName: '已完成' },
    CANCELLED_BY_TEACHER: { displayName: '已取消' },
    CANCELLED_BY_STUDENT: { displayName: '已退出' },
    CANCELLED_BY_SYSTEM: { displayName: '已取消' }
}

export const CourseLanguage = {
    YUE: { displayName: '廣東話' },
    CMN: { displayName: '普通話' },
    ENG: { displayName: '英文' },
    OTHERS: { displayName: '混合語言' },
}

export const CourseMedium = {
    PRERECORDED: { displayName: '影片課程' },
    INTERACTIVE: { displayName: '互動課程' },
    LIVE_STREAMING: { displayName: '網上講座' },
    PHYSICAL: { displayName: '實體活動' },
    FREE_TRIAL: { displayName: '免費試堂' },
    ANY: { displayName: '其他活動' }
}

export const Currency = {
    HKD: { displayName: 'HKD' }
}

export const PaymentSystem = {
    'STRIPE': { displayName: 'Stripe' },
    'STRIPE_CHECKOUT': { displayName: 'Stripe Checkout' },
    'MANUAL': { displayName: 'Manual' },
}

export const CourseCategory = {
    PHILOSOPHY: {
        displayName: '哲學類',
        shortName: '哲學'
    },
    HISTORY: {
        displayName: '歷史類',
        shortName: '歷史'
    },
    HUMANITIES: {
        displayName: '文學/文化類',
        shortName: '文學/文化'
    },
    SOCIAL_SCIENCE: {
        displayName: '社會科學類',
        shortName: '社科'
    },
    SCIENCE: {
        displayName: '科學類',
        shortName: '科學'
    },
    TECHNOLOGY: {
        displayName: '工程/科技類',
        shortName: '工程/科技'
    },
    BUSINESS: {
        displayName: '商學/法律類',
        shortName: '商學/法律'
    },
    LANGUAGE: {
        displayName: '語言類',
        shortName: '語言'
    },
    ARTS: {
        displayName: '藝術類',
        shortName: '藝術'
    },
    LIVING: {
        displayName: '生活/娛樂類',
        shortName: '生活/娛樂'
    },
    SPIRITUAL: {
        displayName: '信仰/心靈類',
        shortName: '信仰/心靈'
    },
    HEALTH: {
        displayName: '健康/飲食類',
        shortName: '健康/飲食'
    },
    CHILD: {
        displayName: '中小學教育',
        shortName: '中小學'
    },
    OTHERS: {
        displayName: '其他',
        shortName: '其他'
    },
}

export const CourseLevel = {
    INTRODUCTORY: { displayName: '入門' },
    INTERMEDIATE: { displayName: '延伸' },
    ADVANCED: { displayName: '進階' }
}

export const EducationLevel = {
    PRIMARY: { displayName: '小學' },
    SECONDARY: { displayName: '中學' },
    TERTIARY: { displayName: '大專' },
    MASTER: { displayName: '碩士' },
    DOCTORATE: { displayName: '博士' },
}

export const TeacherStatus = {
    NONE: { displayName: 'NONE' },
    VERIFIED: { displayName: 'VERIFIED' },
    SUSPENDED: { displayName: 'SUSPENDED' },
}

export const StudentStatus = {
    NONE: { displayName: 'NONE' },
    VERIFIED: { displayName: 'VERIFIED' },
}

export const Gender = {
    MALE: { displayName: '男' },
    FEMALE: { displayName: '女' },
    OTHERS: { displayName: '其他' },
    NOT_SPECIFIED: { displayName: '不願透露' },
}

export const CourseGroupType = {
    DUPLICATE: { displayName: 'DUPLICATE' },
    SERIES: { displayName: 'SERIES' },
}

export const CourseMaterialType = {
    VIDEO: { displayName: 'VIDEO' },
    IMAGE: { displayName: 'IMAGE' },
    DOCUMENT: { displayName: 'DOCUMENT' },
}

export const PasswordHelperText = {
    PASSWORD_EMPTY: { displayName: '請輸入密碼！' },
    CURRENT_PASSWORD_EMPTY: { displayName: '請輸入舊密碼！' },
    SAME_PASSWORD: { displayName: '新密碼不能與舊密碼相同！' },
    NEW_PASSWORD_EMPTY: { displayName: '請輸入新密碼！' },
    NEW_PASSWORD_CONFIRM_EMPTY: { displayName: '請確認新密碼！' },
    INVALID_SAME_PASSWORD: { displayName: '密碼與確認密碼不同，請修正！' },
    CONFIRM_PASSWORD_SUCCESS: { displayName: '密碼相符' },
    INVALID_PASSWORD: { displayName: '密碼輸入錯誤！如註冊時未能有效驗證，請聯絡學識支援。' },
    SHORT_PASSWORD: { displayName: '密碼太短！密碼至少需要8 個位及包含英文字母。' },
    COMMON_PASSWORD: { displayName: '密碼過於常見！請重新輸入更安全的密碼。' },
    NUMERIC_PASSWORD: { displayName: '密碼必須包含英文字母！請重新輸入更安全的密碼。' },
    EMAIL_SIMILAR_PASSWORD: { displayName: '密碼與電子郵件太相似，請使用其他密碼以保護帳號。'},
    PASSWORD_REQUIREMENT_REMINDER: { displayName: '密碼必須有8 個字或以上，當中必須包含英文字母及數字。' },
    PASSWORD_RESET_EMAIL_SENT: { displayName: '已發送密碼重設郵件至 ' },
    PASSWORD_ERROR_EXIST: { displayName: '請先修正密碼。' },
    PASSWORD_TOKEN_INVALID: { displayName: '重設密碼的連結已失效。' },
}

export const AccountHelperText = {
    INVALID_EMAIL: { displayName: '請輸入有效的電郵地址。' },
    REGISTERED_EMAIL: { displayName: '此電郵地址已註冊。如註冊時未能有效驗證，請聯絡學識支援。' },
    EMAIL_EMPTY: { displayName: '請填寫您的電郵地址，以獲得驗證碼登入！' },
    INVALID_RECAPTCHA: { displayName: '請驗證你不是自動程式！' },
    MAX_DATE_MESSAGE: { displayName: '日期不能遲於今天' },
    INVALID_DATE_MESSAGE: { displayName: '出身月日錯誤' },
    MIN_DATE_MESSAGE: { displayName: '日期不能低於最少限制' },
}

export const NameInputHelperText = {
    FIRSTNAME_EMPTY: { displayName: '請輸入名！' },
    LASTNAME_EMPTY: { displayName: '請輸入姓！' },
    DISPLAYNAME_EMPTY: { displayName: '請輸入顯示名稱！' },
}

export const CourseGroupOrdering = {
    RECOMMENDED: { displayName: '推介', sortingId: 'recommended' },
    LATEST: { displayName: '最新', sortingId: 'latest' },
    RELEVANCE: { displayName: '相關度', sortingId: 'relevance' },
};

export const HomePageSearchOption = {
    RECOMMENDED: {
        displayName: '最新',
        params: {priceAmountMin: 0},
        ordering: CourseGroupOrdering.LATEST
    },
    PRERECORDED: {
        displayName: '影片',
        params: {medium: 'PRERECORDED'},
        ordering: CourseGroupOrdering.LATEST
    },
    INTERACTIVE: {
        displayName: '互動',
        params: {medium: 'INTERACTIVE'},
        ordering: CourseGroupOrdering.LATEST
    },
    LIVE_STREAMING: {
        displayName: '講座',
        params: {medium: 'LIVE_STREAMING'},
        ordering: CourseGroupOrdering.LATEST
    },
    ANY: {
        displayName: '實體',
        params: {medium: 'PHYSICAL'},
        ordering: CourseGroupOrdering.LATEST
    },
};

export const CoursesViewOption ={
    TAUGHT: {displayName: '教授的課程'},
    TAKEN: {displayName: '修讀的課程'}
};

export const MyProfileCoursesTaughtTabs = {
    DRAFT: {displayName:'未發佈'},
    ENROLLING: {displayName:'招生中'},
    ONGOING: {displayName:'進行中'},
    COMPLETED: {displayName:'已完成'},
    CANCELLED: {displayName:'已取消'},
};
export const MyProfileCoursesTakenTabs = {
    ENROLLED: {displayName:'已報讀'},
    ONGOING: {displayName:'修讀中'},
    COMPLETED: {displayName:'已完成'},
    CANCELLED: {displayName:'已取消'},
};

export const UserProfileCoursesTabs = {
    ENROLLABLE: {displayName:'接受報名'},
};

export const UpdateLogisticsViewValidationText = {
    INVALID_URL: { displayName: '請輸入有效的連結' },
    INVALID_LENGTH_URL: { displayName: '請輸入長度於1000或以內的連結' }
}

export const RedirectMessage = {
    DEFAULT: '你訪問的頁面只限已登記用戶使用，請先登入或註冊',
    COURSE_ENROLLMENT: '如想報讀任何學識的課程，請先登入或註冊',
};

export const CourseMessageBoardEmailPreference = {
    ALL: { displayName: '接收所有通知' },
    QUOTE_ONLY: { displayName: '被引用時接收通知' },
    NONE: { displayName: '不接收任何通知' },
};
