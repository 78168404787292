import { bindActionCreators } from 'redux';

export function getCourseDetails(courseId) {
    return async (_dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.getCourseDetails(token, courseId);
    };
}

export function getCourseGroupDetails(courseGroupId) {
    return async (_dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.retrieveCourseGroupDetails(token, courseGroupId);
    };
}

export function retrieveCourseLogistics(courseGroupId) {
    return async (_dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.retrieveCourseLogistics(token, courseGroupId);
    };
}

export function createCourseAnnouncement(
    courseId,
    message,
    sendsNotification
) {
    return async (_dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.createCourseAnnouncement(
            token, 
            courseId,
            message,
            sendsNotification
        );
    };
}

export function updateCourseLogistics(
    courseId,
    meetingUrl,
    courseMaterialsUrl,
    meetingDetails
) {
    return async (_dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.updateCourseLogistics(
            token,
            courseId,
            meetingUrl,
            courseMaterialsUrl,
            meetingDetails
        );
    };
}

export function getCourseStatusOverview(
    courseId
) {
    return async (_dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.getCourseStatusOverview(
            token,
            courseId
        );
    };
}

export function publishCourse(
    courseId
) {
    return async (_dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.publishCourse(
            token,
            courseId
        );
    };
}

export function retrieveCourseRatingInfo(
    courseId
) {
    return async (_dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.retrieveCourseRatingInfo(
            token,
            courseId
        );
    };
}

export function updateCourseRating(
    courseId,
    score,
    comment
) {
    return async (_dispatch, getState, {apiClient}) => {
        const token = getState().auth.token;
        return await apiClient.updateCourseRating(
            token,
            courseId,
            score,
            comment
        );
    };
}

export function retrieveCourseGroupRatingInfo(
    groupId
) {
    return async (_dispatch, getState, {apiClient}) => {
        return await apiClient.retrieveCourseGroupRatingInfo(groupId);
    };
}

export function listCourseGroupRatings(
    groupId,
    page
) {
    return async (_dispatch, getState, {apiClient}) => {
        return await apiClient.listCourseGroupRatings(groupId, page);
    };
}

export function searchCourseGroup(
    params,
    page,
    ordering
) {
    return async (_dispatch, getState, {apiClient}) => {
        return await apiClient.searchCourseGroup(params, page, ordering);
    };
}

export function validateCourseAdminAccess(courseId) {
    return async (_dispatch, getState, { apiClient }) => {
        return await apiClient.validateCourseAdminAccess(getState().auth.token, courseId);
    }
}

export function exportCourseEnrolledStudentsCSV(courseId) {
    return async (_dispatch, getState, { apiClient }) => {
        return await apiClient.exportCourseEnrolledStudentsCSV(getState().auth.token, courseId);
    }
}

export function retrieveCourseMessageBoardInfo(courseId) {
    return async (_dispatch, getState, { apiClient }) => {
        return await apiClient.retrieveCourseMessageBoardInfo(getState().auth.token, courseId);
    }
}

export function createCourseMessage(courseId, content, quoteId) {
    return async (_dispatch, getState, { apiClient }) => {
        return await apiClient.createCourseMessage(
            getState().auth.token,
            courseId,
            content,
            quoteId
        );
    }
}

export function retrieveCourseMessageBoardPreference(courseId) {
    return async (_dispatch, getState, { apiClient }) => {
        return await apiClient.retrieveCourseMessageBoardPreference(getState().auth.token, courseId);
    }
}

export function updateCourseMessageBoardPreference(
    courseId,
    emailNotification
) {
    return async (_dispatch, getState, { apiClient }) => {
        return await apiClient.updateCourseMessageBoardPreference(getState().auth.token, courseId, emailNotification);
    }
}

export function createCourseActionCreator(
    dispatch,
) {
    return bindActionCreators(
        {
            getCourseDetails,
            getCourseGroupDetails,
            retrieveCourseLogistics,
            createCourseAnnouncement,
            updateCourseLogistics,
            getCourseStatusOverview,
            publishCourse,
            retrieveCourseRatingInfo,
            updateCourseRating,
            retrieveCourseGroupRatingInfo,
            listCourseGroupRatings,
            searchCourseGroup,
            exportCourseEnrolledStudentsCSV,
            retrieveCourseMessageBoardInfo,
            createCourseMessage,
            retrieveCourseMessageBoardPreference,
            updateCourseMessageBoardPreference,
        },
        dispatch,
    );
}
