import React, { useEffect, useState } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import {
    getCourseDetailsUrl,
    getCourseLogisticsUrl,
    getCourseRecordingsUrl,
    getCourseManagementUrl,
} from '../../utils';
import { validateCourseAdminAccess } from '../../actions/courseAction';


const useStyles = makeStyles(() => ({
    managementButton: {
        color: "#fff",
    },
    managementButtonSpace: {
        marginLeft: '50px',
        maxWidth: '120px',
    },
}));

export const ManagementButton = props => {
    const classes = useStyles(useTheme());
    const { target, packed } = props;

    const dispatch = useDispatch();
    const [btnAccess, setBtnAccess] = useState(false);

    useEffect(() => {
        // This btn could be unmounted due to adaptive design
        // Ensure hook is mounted before perform state update
        let mounted = true;

        dispatch(validateCourseAdminAccess(target.id))
            .then((result) => {
                if (mounted) {
                    setBtnAccess(result);
                }
            })
            .catch(() => {
                if (mounted) {
                    setBtnAccess(false);
                }
            });

        return () => {
            mounted = false;
        }
    }, []);

    return (
        <>
            { btnAccess &&
                <Button
                    className={classNames(classes.managementButton, !packed && classes.managementButtonSpace)}
                    component={Link}
                    variant="contained"
                    color="secondary"
                    to={getCourseManagementUrl(target.id)}
                >
                    導師專用平台
                </Button>
            }
        </>
    );
}
ManagementButton.propTypes = {
    target: PropTypes.object.isRequired,
    packed: PropTypes.bool,
};
ManagementButton.defaultProps = {
    packed: false,
}

const CourseTitleNavigation = ({
    target,
    disableCourseDetails,
    disableLogistics,
    disableCourseRecordings,
    disableCourseManagement,
}) => {
    return (
        <Grid container spacing={1}>
            {!disableCourseDetails && <Grid item>
                <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    to={getCourseDetailsUrl(target.id)}
                >
                    活動介紹
                </Button>
            </Grid>}

            {!disableLogistics && <Grid item>
                <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    to={getCourseLogisticsUrl(target.id)}
                >
                    通訊互動
                </Button>
            </Grid>}

            {!disableCourseRecordings && <Grid item>
                <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    to={getCourseRecordingsUrl(target.id)}
                >
                    觀看影片
                </Button>
            </Grid>}
            {!disableCourseManagement && <Grid item><ManagementButton target={target} packed={true}/></Grid>}
        </Grid>
    );
}

CourseTitleNavigation.propTypes = {
    target: PropTypes.object.isRequired,
    disableCourseDetails: PropTypes.bool,
    disableLogistics: PropTypes.bool,
    disableCourseRecordings: PropTypes.bool,
    disableCourseManagement: PropTypes.bool,
}

CourseTitleNavigation.defaultProps = {
    disableCourseDetails: false,
    disableLogistics: false,
    disableCourseRecordings: false,
    disableCourseManagement: true,
}

export default CourseTitleNavigation;
