import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames/bind';

import Avatar from '@material-ui/core/Avatar';
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import { Link, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    convertRatingDistributionToPercentage,
    getProfileUrl,
    getTeacherRatingUrl
} from '../../utils';
import { RATING_MAX } from '../../constants';

export const StyledRating = withStyles(theme => ({
    iconFilled: {
        color: theme.palette.primary.main,
    },
}))(Rating);

const useStyles = makeStyles((theme) => ({
    avatar: {
        border: '2.5px solid #EEEEEE',
        minWidth: '85px',
        minHeight: '85px',
        [theme.breakpoints.down('xs')]: {
            minWidth: '74px',
            minHeight: '74px',
        }
    },
    avatarContainer: {
        paddingRight: '10px',
    },
    cardRoot: {
        minWidth: '200px',
    },
    cardContent: {
        paddingRight: '0px',
        paddingLeft: '0px',
        "&:last-child": {
            paddingBottom: 0
        }
    },
    name: {
        color: '#000000',
        fontSize: '20px',
        paddingLeft: '3px',
    },
    ratingSpan: {
        display: 'inline-flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    ratingText: {
        ...theme.text.content,
        marginLeft: '5px',
        color: '#999999',
    },
    selfIntroduction: {
        ...theme.text.content,
        color: '#999999',
        textAlign: 'justify',
    },
    selfIntroductionWrapper:{
        padding: '15px 20px 0 0px'

    },
    teacherRatingPageLink: {
        color: '#6293C3',
        fontWeight: 600,
        textDecoration: 'none',
    },
}));

export function TeacherRatingText(props) {
    const classes = useStyles();
    const { ratingDistribution, ratingAverage, textClass, teacherId, ...otherProps } = props;
    const { total } = convertRatingDistributionToPercentage(ratingDistribution);
    const ratingAverageDisplayed = ratingAverage ? ratingAverage.toFixed(2) : ' - ';

    const isRatingPage = useRouteMatch("/user/:userId/ratings");

    return (
        <>
            <span className={classes.ratingSpan}>
                <StyledRating size='small' value={ratingAverage} precision={0.5} readOnly {...otherProps} />
                {<Typography component='span' className={textClass}>{ratingAverageDisplayed}/{RATING_MAX.toFixed(2)}</Typography>}
            </span>
            {
                total ? (
                    <Typography component='span' className={textClass}>
                        {`(${total}個評分)`}
                        {(teacherId && !isRatingPage) &&
                            <Link to={getTeacherRatingUrl(teacherId)} className={classes.teacherRatingPageLink}>
                                {" - 導師詳細評價"}
                            </Link>
                        }
                        {(teacherId && isRatingPage) &&
                            <Link to={getProfileUrl(teacherId)} className={classes.teacherRatingPageLink}>
                                {" - 返回導師頁面"}
                            </Link>
                        }
                    </Typography>
                ) : (
                    <Typography component='span' className={textClass}>暫時未有評分</Typography>
                )
            }
        </>
    )
}

TeacherRatingText.propTypes = {
    ratingDistribution: PropTypes.array.isRequired,
    ratingAverage: PropTypes.number,
    textClass: PropTypes.string,
    teacherId: PropTypes.number.isRequired,
}

function TeacherRatingSummary(props) {
    const classes = useStyles();
    const { ratingDistribution, ratingAverage, displayName, avatarUrl, teacherId, className, teacherSelfIntroduction } = props;

    return (
        <Link to={getProfileUrl(teacherId)} style={{ textDecoration: 'none' }}>
            <Card className={classes.cardRoot} elevation={0}>
                <CardContent className={classNames(className, classes.cardContent)}>
                    <Grid container direction='row' alignItems='stretch'>
                        <Grid item className={classes.avatarContainer}>
                            <Avatar
                                className={classes.avatar}
                                alt={displayName}
                                src={avatarUrl}
                            />
                        </Grid>
                        
                        <Grid container item xs={8} direction='column'>
                            <Grid item><Typography className={classes.name}>{displayName}</Typography></Grid>
                            <Grid container direction='row'>
                                <TeacherRatingText
                                    ratingDistribution={ratingDistribution}
                                    ratingAverage={ratingAverage}
                                    textClass={classes.ratingText}
                                    teacherId={teacherId}
                                />
                            </Grid>
                            
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} className={classes.selfIntroductionWrapper}>
                                <Typography className={classes.selfIntroduction}>{teacherSelfIntroduction}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Link>
    );
}

TeacherRatingSummary.propTypes = {
    className: PropTypes.string,
    ratingDistribution: PropTypes.array,
    ratingAverage: PropTypes.number,
    displayName: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
    teacherId: PropTypes.number.isRequired,
    teacherSelfIntroduction: PropTypes.string,
}

export default TeacherRatingSummary;