import React from 'react';
import {Link} from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    readAndAgreeText: {
        color: theme.palette.text.secondary,
    },
    link: {
        color: '#6293C3',
        textDecoration: 'none',
    }
}));

const ReadAndAgreeTermsAndConditionsText = () => {
    const classes = useStyles();

    return (
        <Typography className={classes.readAndAgreeText}>
            {'我已閱讀並同意 '}
            <Link
                className={classes.link}
                target='_blank'
                to='/service-agreement'
            >一般用戶服務條款</Link>
            {' 及 '}
            <Link
                className={classes.link}
                target='_blank'
                to='/privacy-policy'
            >私隱政策</Link>
        </Typography>
    )
}

export default ReadAndAgreeTermsAndConditionsText;