import React, { useRef, useCallback,useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import _ from '../../lodash-wrapper';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player/youtube';

const useStyles = makeStyles(() => ({
    videoPlayerContainer: {
        width: '100%',

    },
}));

const PromotionalVideo = props => {
    const classes = useStyles(useTheme());
    const { target } = props;
    const hasVideo =  _.size(target.promotionalVideoUrl) > 0;
    const ref = useRef();
    const [playerHeight, setPlayerHeight] = useState('500px');
    const setRef = useCallback(node => {
        ref.current = node;
    }, []);
    
    useEffect(() => {
        const handleResize = () => {
            if (ref.current) {
                const newHeight = `${ref.current.clientWidth / 16 * 9}px`;
                setPlayerHeight(newHeight);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window]);

    if(!hasVideo)
        return null;
    return (
        <>
        <div ref={setRef} className={classes.videoPlayerContainer} style={{}}>
            <ReactPlayer
                url={target.promotionalVideoUrl}
                width='100%'
                height={playerHeight}

            />
        </div>
        </>
    );
}
PromotionalVideo.propTypes = {
    target: PropTypes.object.isRequired,
}

export default PromotionalVideo;